import * as nomo_kit from "nomo-webon-kit";
import { KycProofStatus } from "./constants";
import { zenPass, zscSigner } from "./contractUtils";
import { goto } from '$app/navigation';
import { stringifyWithTruncation } from '$lib/utils/stringifyWithTruncate';

const nomo = nomo_kit.nomo;

const api = import.meta.env.VITE_API;

export const  callApi = async (method: "GET" | "POST", endpoint: string, body?: string | null, headers?: {
	[key: string]: string;
}) => {
	console.log("callApi", method, endpoint, headers, body);
	const http_args: {
		url: string;
		method?: "GET" | "POST";
		headers?: {
			[key: string]: string;
		};
		body?: string;
	} = {
		url: api + endpoint,
		method: method || 'GET',
		headers: headers || {}
	};

	if(body) {
		http_args.body = body;
	}

	try {
		const resJson = await nomo.authFetch(http_args);

		if (resJson.statusCode === 0) {
			throw new Error('Server unavailable');
		}

		if (resJson.statusCode !== 200) {
			console.log(endpoint, JSON.stringify(resJson, null, 2));
			throw new Error(endpoint + "  API call failed");
		}
		const resObj = await JSON.parse(resJson.response);
		return resObj.data;
	} catch (error) {
		console.error('API call failed:', error);
		if(error?.constructor.name === 'String') {
			if (error.toString().includes('Connection refused')) {
				goto('/server-unavailable');
			}
		}
		throw error;
	}
};

export const getKycServiceId = (services: any[], name: string) => {
	return services.find(service => service.name === name).id;
};

export const handleNftCheck = async (): Promise<boolean> => {
    const address = await zscSigner.getAddress();
    const nftId = await zenPass.nftByAddress(address);
    return nftId.toBigInt() !== 0n;
};

export async function checkServerAvailability(): Promise<boolean> {
	try {
		const response = await fetch(api + '/health');
		return response.ok;
	} catch (error) {
		console.error('Server availability check failed:', error);
		return false;
	}
}